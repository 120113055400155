import React, { useEffect, useState } from "react";
import SelectConnectedField from "../../forms/SelectConnectedField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { customActionsPopUps } from "../../../../redux/actions/customActionsPopUps";
import { setCustomPopUpOpen } from "../../../../redux/actions/popUps";

import _ from "lodash";
import TalentoButton from "../../TalentoButton";

const useStyles = makeStyles((theme) => ({
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
  submitButton: {
    margin: theme.spacing(2),
  },
}));

const PopUpForMassAddCareerManager = (props) => {
  const {
    gridConfig,
    gridApi,
    isCustomPopUpOpen,
    webhook = "associateCareerManagers",
  } = props;

  const methods = useForm({ mode: "onTouched" });
  const { handleSubmit, control, getValues } = methods;

  const classes = useStyles();
  const [isAllDataCorrect, setIsAllDataCorrect] = useState();
  const [field, setField] = useState();
  const [object, setObject] = useState();

  useEffect(() => {
    const fieldToSet =
      _.find(gridConfig, (o) => {
        return o.key === "field_473";
      }) ||
      _.find(gridConfig, (o) => {
        return o.key === "field_317";
      });
    setField(fieldToSet);

    fieldToSet?.key === "field_473"
      ? setObject("list_of_internships")
      : setObject("list_of_classes");
  }, [gridConfig]);

  const onSubmit = (formData) => {
    if (formData[field.key].id) {
      props.customActionsPopUps(webhook, gridApi, {
        careerManagerId: formData[field.key].id,
        identifier: formData[field.key].identifier,
        object: object,
      });
      props.setCustomPopUpOpen(false);
    } else setIsAllDataCorrect(false);
  };

  if (!gridApi || !gridConfig) return null

  return (
    <Dialog
      open={isCustomPopUpOpen}
      maxWidth="xs"
      fullWidth={true}
      classes={{ paperFullWidth: classes.paperFullWidth }}
    >
      <Grid
        container
        style={{ padding: 10, display: "flex", justifyContent: "center" }}
      >
        <DialogTitle>
          <Typography>Selecione o Gestor de Carreira</Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          <Grid item xs={12}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TableContainer>
                  <Table aria-label="simple tabel">
                    <TableBody>
                      {field ? (
                        <SelectConnectedField
                          control={control}
                          field={field}
                          recordValues=""
                          object="object_24"
                          getValues={getValues}
                          required
                        />
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* </Grid> */}
                <Grid item xs={12}>
                  {isAllDataCorrect === false && (
                    <Typography
                      color="error"
                      style={{ marginTop: 20, textAlign: "center" }}
                    >
                      Todos os campos tem que estar prenchidos, por favor
                      verifique e tente de novo.
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  <TalentoButton
                    onClick={() => props.setCustomPopUpOpen(false)}
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 10 }}
                  >
                    Cancelar
                  </TalentoButton>
                  <TalentoButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    Executar
                  </TalentoButton>
                </Grid>
              </form>
            </FormProvider>
          </Grid>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  customActionsPopUps,
  setCustomPopUpOpen,
})(PopUpForMassAddCareerManager);
