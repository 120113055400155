import React from "react";
import { connect } from "react-redux";
import * as CustomPopUps from "../../customComponents/index";

const CustomPopUp = ({
  gridConfig,
  gridApi,
  id,
  customActions,
  isCustomPopUpOpen,
  record = null
}) => {
  const actionType = customActions?.type || null;
  const componentName = customActions?.componentName || null;

  if (!actionType || !componentName) return null;
  if (actionType && componentName) {
    const Component = CustomPopUps[componentName];
    return (
      <Component
        id={id}
        gridConfig={gridConfig}
        gridApi={gridApi}
        isCustomPopUpOpen={isCustomPopUpOpen}
        record={record}
      />
    );
  }
};

const mapStateToProps = (state) => ({
  customActions: state.actions,
  isCustomPopUpOpen: state.customPopUp,
});

export default connect(mapStateToProps, null)(CustomPopUp);
