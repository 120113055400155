import React from "react";
import DetailView from "../../layout/Detail/DetailView";
import AddForm from "../../layout/forms/AddForm";
import EditForm from "../../layout/forms/EditForm";
import ListView from "../../layout/ListView";
import { Profiles } from "../../../utils/knackKeys";

// SHARED CONSTANTS

const object = "object_56";
const objectLabel = "Centros Qualifica";
const objectLabelSingular = "Centro-Qualifica";
const objectLabelPlural = "Centros-Qualifica";
const listUrl = "/centros-qualifica";
const userPermissions = {
  userPermissionsField: "",
  viewRolesPermissions: [Profiles.ORIENTADOR_PEDAGOGICO],
  ignoreUserPermissionsField: true
};

const DetailQualificaCenter = (props) => (
  <DetailView
    object={object}
    objectLabelSingular={objectLabelSingular}
    objectLabelPlural={objectLabelPlural}
    id={props.match.params.id}
    scene={"scene_394"}
    view={"view_508"}
    sceneEdit={"scene_393"}
    viewEdit={"view_507"}
    userPermissions={userPermissions}
    deletable={true}
    tabs={[
      {
        title: "Turmas",
        position: 1,
        params: {
          object: "object_7",
          objectLabelPlural: "Turmas",
          objectLabelSingular: "Turma",
          slug: "turmas",
          scene: "scene_43",
          view: "view_53",
          userPermissions: userPermissions,
          extraConfigs: {
            showCreateButton: "no",
          },
          filterByConnectedField: {
            connected_field: "field_1066",
            id: props.match.params.id,
          },
        },
      }
    ]}
  />
);

const FormAddQualificaCenter = (props) => (
  <AddForm
    objectLabel={objectLabel}
    object={object}
    listUrl={listUrl}
    scene={"scene_391"}
    view={"view_509"}
    userPermissions={userPermissions}
  />
);

const FormEditQualificaCenter = (props) => (
  <EditForm
    objectLabel={objectLabel}
    object={object}
    listUrl={listUrl}
    id={props.match.params.id}
    scene={"scene_393"}
    view={"view_507"}
    userPermissions={userPermissions}
  />
);

const ListQualificaCenters = (props) => (
  <ListView
    object={object}
    objectLabelPlural={objectLabelPlural}
    objectLabelSingular={objectLabelSingular}
    scene={"scene_391"}
    view={"view_506"}
    extraConfigs={{}}
    userPermissions={userPermissions}
  />
);

export { DetailQualificaCenter, FormAddQualificaCenter, FormEditQualificaCenter, ListQualificaCenters };
