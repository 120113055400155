import {
  ASSOCIATE_CAREER_MANAGERS,
  CLEAR_TABLE_STATE,
  SEND_MASS_SMS,
  SEND_MASS_EMAIL,
} from "../constants/customActions";
import {
  associateCareerManagersWebhook,
  associateCareerManagerProcuraAtivaWebhook,
  changeStudentsContractStatusWebhook,
  sendMassEmailWebhook,
  createPresencesManuallyWebhook,
  sendMassSmsWebhook,
  cancelSignatureWebhook,
} from "../../utils/webhooks";
import callWebhook from "./api/callWebhook";
import notify from "./helper";
import { ERROR, SUCCESS, WARNING } from "./notifications";
import { EDIT_RECORD } from "../constants/dataApiCalls";

export const customActionsPopUps =
  (customActionId, gridApi, extraVariables = {}) =>
    async (dispatch) => {
      switch (customActionId) {
        case "associateCareerManagers":
          dispatch({ type: ASSOCIATE_CAREER_MANAGERS, payload: true });
          notify({
            dispatch,
            type: WARNING,
            message: "A alteração do Gestores de Carreira está a ser processada",
          });
          const associateCareerManagersMessage = await associateCareerManagers(
            gridApi,
            extraVariables
          );
          notify({
            dispatch,
            type: associateCareerManagersMessage.type,
            message: associateCareerManagersMessage.message,
          });
          break;
        case "associateCareerManagerProcuraAtiva":
          dispatch({ type: ASSOCIATE_CAREER_MANAGERS, payload: true });
          notify({
            dispatch,
            type: WARNING,
            message:
              "A alteração do Gestores de Carreira da sessão de Procura Ativa está a ser processada",
          });
          const associateGCProcuraAtivaMessage =
            await associateCareerManagerProcuraAtiva(gridApi, extraVariables);
          notify({
            dispatch,
            type: associateGCProcuraAtivaMessage.type,
            message: associateGCProcuraAtivaMessage.message,
          });
          break;
        case "changeStudentsContractStatus":
          notify({
            dispatch,
            type: WARNING,
            message: "A alteração do estado dos contratos está a ser processada",
          });
          const changeStudentsContractStatusMessage =
            await changeStudentsContractStatus(gridApi, extraVariables);
          notify({
            dispatch,
            type: changeStudentsContractStatusMessage.type,
            message: changeStudentsContractStatusMessage.message,
          });
          break;
        case "sendMassSMS":
          dispatch({ type: SEND_MASS_SMS, payload: true });
          notify({
            dispatch,
            type: WARNING,
            message: "O envio dos SMS está a ser processado",
          });
          const sendMassSMSMessage = await sendMassSMS(gridApi, extraVariables);
          notify({
            dispatch,
            type: sendMassSMSMessage.type,
            message: sendMassSMSMessage.message,
          });
          break;
        case "sendMassEmail":
          dispatch({ type: SEND_MASS_EMAIL, payload: true });
          notify({
            dispatch,
            type: WARNING,
            message: "O envio dos emails está a ser processado",
          });
          const sendMassEmailMessage = await sendMassEmail(
            gridApi,
            extraVariables
          );
          notify({
            dispatch,
            type: sendMassEmailMessage.type,
            message: sendMassEmailMessage.message,
          });
          break;
        case "createPresencesManually":
          notify({
            dispatch,
            type: WARNING,
            message: "As presenças estão a ser criadas, aguarde um momento",
          });
          const result = await createPresencesManually(extraVariables);
          if (result?.type === ERROR)
            notify({ dispatch, type: ERROR, message: result?.message });
          break;
        case "cancelSignature": {
          const result = await cancelSignature(extraVariables);
          notify({ dispatch, type: SUCCESS, message: "Contrato cancelado com sucesso!" });
          dispatch({ type: EDIT_RECORD, payload: extraVariables.record });

          if (result?.type === ERROR)
            notify({ dispatch, type: ERROR, message: result?.message });
          break;
        }
        default:
          return null;
      }
    };

const associateCareerManagers = async (gridApi, extraVariables) => {
  return await processData({
    gridApi,
    extraVariables,
    webhookUrl: associateCareerManagersWebhook,
    successMessage:
      "A alteração dos gestores de carreira foi concluída com sucesso 😄",
  });
};

const associateCareerManagerProcuraAtiva = async (gridApi, extraVariables) => {
  return await processData({
    gridApi,
    extraVariables,
    webhookUrl: associateCareerManagerProcuraAtivaWebhook,
    successMessage:
      "A alteração do Gestor de Carreira da sessão de Procura Ativa foi concluída com sucesso 😄",
    filteringFunction: (element) => ({ id: element.id }),
  });
};

const changeStudentsContractStatus = async (gridApi, extraVariables) => {
  return await processData({
    gridApi,
    extraVariables,
    webhookUrl: changeStudentsContractStatusWebhook,
    successMessage:
      "A alteração do estado dos contratos foi concluída com sucesso 😄",
    filteringFunction: (element) => ({ contractId: element.id }),
  });
};

const sendMassSMS = async (gridApi, extraVariables) => {
  return await processData({
    gridApi,
    extraVariables,
    webhookUrl: sendMassSmsWebhook,
    successMessage:
      "Ação terminada. Verifique sempre se os SMS foram devidamente enviados",
    filteringFunction: (element) => ({ id: element.id }),
  });
};

const sendMassEmail = async (gridApi, extraVariables) => {
  return await processData({
    gridApi,
    extraVariables,
    webhookUrl: sendMassEmailWebhook,
    successMessage:
      "Ação terminada. Verifique sempre se os emails foram devidamente enviados",
    filteringFunction: (element) => ({ id: element.id }),
  });
};

const createPresencesManually = async (extraVariables) => {
  try {
    await callWebhook({
      webhook: createPresencesManuallyWebhook,
      body: { data: extraVariables },
    });
  } catch (error) {
    return {
      type: ERROR,
      message: `Ocorreu um erro ao completar a ação: ${error?.response?.data?.message || error?.response?.data || error.message
        }`,
    };
  }
};

const cancelSignature = async ({ contractId, reason }) => {
  try {
    const result = await callWebhook({
      webhook: cancelSignatureWebhook,
      body: { contractId, reason },
    });
    return {
      type: SUCCESS,
      message: result?.data?.message
    }
  } catch (error) {
    return {
      type: ERROR,
      message: `Ocorreu um erro ao completar a ação: ${error?.response?.data?.message || error?.response?.data || error.message
        }`,
    };
  }
};

// eslint-disable-next-line no-unused-vars
const processData = async ({
  gridApi,
  extraVariables,
  webhookUrl,
  successMessage,
  filteringFunction,
}) => {
  try {
    const selectedNodes = gridApi.getSelectedNodes();

    let data = [];
    selectedNodes.forEach((node) => {
      const arrayElement = !!filteringFunction
        ? filteringFunction(node.data)
        : node.data;
      data.push(arrayElement);
    });

    if (selectedNodes.length === 0) {
      return {
        type: ERROR,
        message: "Não selecionou linhas, por favor tente de novo",
      };
    }

    await callWebhook({
      webhook: webhookUrl,
      body: { extraVariables, data },
    });

    return { type: SUCCESS, message: successMessage };
  } catch (error) {
    return {
      type: ERROR,
      message: `Ocorreu um erro ao completar a ação: ${error?.response?.data?.message || error?.response?.data || error.message
        }`,
    };
  }
};

export const clearTableState = () => (dispatch) => {
  dispatch({
    type: CLEAR_TABLE_STATE,
  });
};
