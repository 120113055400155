import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import TalentoButton from "../../TalentoButton";
import Message from "../../ListViewTab/Message";

const DigitalDocumentsArea = ({
  message,
  documentsToSign,
  displayDigitalDocumentSection,
  createDocument,
  canUserCreateDigitalDocument,
  classes,
  record
}) => {
  if (!displayDigitalDocumentSection) return null;
  return (
    <Paper elevation={3} className={classes.documentActions}>
      <Grid style={{ margin: 10 }}>
        <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
          Assinatura Digital
        </Typography>
        <div>
          {record && <Message style={{ marginBottom: '1.5rem' }} messages={message} useSessionStorage={false} />}
          <div style={{ display: 'flex', flexDirection: "column" }}>
            {documentsToSign.map((documentToSign) => (
              <TalentoButton
                key={documentToSign.type}
                variant={documentToSign.type === 'cancel_signature' ? "outlined" : "contained"}
                color="secondary"
                onClick={() => createDocument(documentToSign)}
                disabled={canUserCreateDigitalDocument}
                style={{ marginBottom: "0.8rem", width: "20rem" }}
              >
                {documentToSign.title}
              </TalentoButton>
            ))}
          </div>
        </div>
      </Grid>
    </Paper>
  );
};

export default DigitalDocumentsArea;
