import React from "react";
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  Font,
  View,
  // PDFViewer,
} from "@react-pdf/renderer";
import FrontBackground from "../../assets/images/diploma_front_text.png";
import BackBackground from "../../assets/images/diploma_back.png";
import Signature from "../../assets/images/diploma_signature.png";
import MontserratRegular from "../../assets/fonts/Montserrat/Montserrat-Regular.ttf";
import MontserratMedium from "../../assets/fonts/Montserrat/Montserrat-Medium.ttf";
import MontserratSemiBold from "../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf";
import MontserratBold from "../../assets/fonts/Montserrat/Montserrat-Bold.ttf";
import MontserratExtraBold from "../../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf";
import MontserratLight from "../../assets/fonts/Montserrat/Montserrat-Light.ttf";
import moment from "moment";
import convertToRomanNumber from "../../utils/convertToRomanNumber";

const normalizeString = (string) =>
  string.normalize("NFD").replace(/\p{Diacritic}/gu, "");

const regex = new RegExp("(?<=- |– ).*", "gi");

const DiplomaFront = ({
  classEndDate,
  classCourseName,
  courseHours,
  studentName,
  grade,
}) => (
  <Page size="A4" style={[styles.page, { position: "relative" }]}>
    <div style={styles.front.textContainer}>
      <Text style={styles.front.title}>
        CERTIFICAMOS PARA OS DEVIDOS EFEITOS QUE
      </Text>
      <Text style={styles.front.studentName} render={() => studentName} />
      <Text style={styles.front.body}>
        CONCLUIU COM ÊXITO, EM{" "}
        <Text>{moment(classEndDate).format("MMMM")}</Text> DE{" "}
        <Text>{moment(classEndDate).format("YYYY")}</Text>,{"\n"}O CURSO DE{" "}
        <Text>{classCourseName}</Text>
        {"\n"}
        COM A CARGA HORÁRIA DE <Text>{courseHours}</Text> HORAS,
        {"\n"}
        PROMOVIDO PELA TALENTO - FORMAÇÃO
        {"\n"}
      </Text>
      <Text style={styles.front.grade}>
        NOTA FINAL: <Text>{grade}</Text> VALORES
      </Text>
      <div style={styles.front.signatureContainer}>
        <Image src={Signature} style={styles.front.signature} />
        <Text style={styles.front.signatureText}>
          ____________________________________________________________
          {"\n"}
          COORDENADORA PEDAGÓGICA
        </Text>
      </div>
    </div>
    <View style={styles.container}>
      <Image src={FrontBackground} style={styles.background} />
    </View>
  </Page>
);

const DiplomaBack = ({ modules, jobOpportunities, courseHours }) => {
  const processedModules = () =>
    modules.map((module) => {
      let name = module.field_381;
      const number = module.field_593;
      const workload = module.field_392;

      const normalizedName = normalizeString(name).toLowerCase();
      const hasModule = normalizedName.includes("modulo");

      if (hasModule) name = name.match(regex)?.[0];

      const title = `MÓDULO ${convertToRomanNumber(Number(number))}`;

      return { title, name, workload };
    });

  return (
    <Page size="A4" style={[styles.page, { position: "relative" }]}>
      <View style={styles.back.textContainer}>
        <View>
          <Text style={styles.back.title}>MÓDULOS</Text>
          <div style={{ display: "flex" }}>
            {processedModules().map(({ title, name, workload }, index) => (
              <div
                key={index.toString()}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Text style={styles.back.listTitle}>{title}</Text>
                <Text style={styles.back.listText}>{name}</Text>
                <Text style={styles.back.listEnd}>{workload}H</Text>
              </div>
            ))}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 8,
              }}
            >
              <Text style={{ fontWeight: 700, fontSize: 10 }}>
                DURAÇÃO TOTAL DA FORMAÇÃO
              </Text>
              <Text style={styles.back.listEnd}>{courseHours}H</Text>
            </div>
          </div>
        </View>
        {jobOpportunities && (
          <View>
            <Text style={styles.back.title2}>SAÍDAS PROFISSIONAIS</Text>
            <Text style={styles.back.bodyText}>{jobOpportunities}</Text>
          </View>
        )}
      </View>
      <View style={styles.container}>
        <Image src={BackBackground} style={styles.background} />
      </View>
    </Page>
  );
};

export const Diploma = ({
  classEndDate,
  classCourseName,
  courseHours,
  studentName,
  grade,
  modules,
  jobOpportunities
}) => {
  return (
    // <PDFViewer style={{ height: "100vh", width: "100%" }}>
      <Document>
        <DiplomaFront
          classEndDate={classEndDate}
          classCourseName={classCourseName}
          courseHours={courseHours}
          studentName={studentName}
          grade={grade}
        />
        <DiplomaBack
          modules={modules}
          jobOpportunities={jobOpportunities}
          courseHours={courseHours}
        />
      </Document>
    // </PDFViewer>
  );
};

Font.register({
  family: "Montserrat",
  fonts: [
    { src: MontserratRegular }, // font-style: normal, font-weight: normal
    { src: MontserratMedium, fontWeight: 500 },
    { src: MontserratSemiBold, fontWeight: 600 },
    { src: MontserratBold, fontWeight: 700 },
    { src: MontserratExtraBold, fontWeight: 800 },
    { src: MontserratLight, fontWeight: 300 },
  ],
});

// Prevent hyphenation from happening
Font.registerHyphenationCallback(word => [word]);

const boldFont = {
  fontWeight: 700,
  letterSpacing: 2,
};

const styles = StyleSheet.create({
  page: {
    fontFamily: "Montserrat",
    fontSize: 20,
    color: "#24303A",
  },
  front: {
    textContainer: {
      top: 250,
    },
    title: {
      ...boldFont,
      fontSize: 12,
      textAlign: "center",
    },
    studentName: {
      ...boldFont,
      marginTop: 15,
      marginBottom: 20,
      marginHorizontal: 50,
      lineHeight: 1.5,
      textAlign: "center",
      textTransform: "uppercase",
      hyphens: "none",
      wordBreak: "break-all",
    },
    body: {
      fontSize: 14,
      marginHorizontal: 50,
      textAlign: "center",
      marginTop: "30px",
      textTransform: "uppercase",
      wordBreak: "break-all",
      lineHeight: 1.5,
      hyphens: "none",
    },
    grade: {
      ...boldFont,
      margin: 12,
      marginTop: 50,
      textAlign: "center",
      textTransform: "uppercase",
      width: "100%",
      fontSize: 22,
    },
    signatureContainer: {
      marginTop: "30px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },
    signature: {
      position: "absolute",
      width: 300,
      top: 0,
      right: 130,
    },
    signatureText: {
      top: 65,
      position: "absolute",
      fontSize: 9,
      textAlign: "center",
      lineHeight: 1.5,
    },
  },
  back: {
    textContainer: {
      marginTop: 150,
      marginLeft: "55px",
      marginRight: "55px",
      lineHeight: 2,
    },
    title: {
      ...boldFont,
      fontSize: 16,
      marginBottom: 10,
    },
    title2: {
      ...boldFont,
      fontSize: 16,
      marginTop: 25,
      marginBottom: 8,
    },
    bodyText: {
      fontSize: 10,
      lineHeight: 1.8,
    },
    listTitle: {
      fontWeight: 700,
      fontSize: 9,
      width: "100px",
    },
    listText: {
      fontSize: 9,
      width: "390px",
      marginRight: "10px",
      wordBreak: "break-all",
    },
    listEnd: {
      fontWeight: 700,
      fontSize: 10,
      width: "60px",
      textAlign: "right",
    },
  },
  container: {
    minWidth: "100%",
    minHeight: "100%",
    display: "block",
    height: "100%",
    width: "100%",
    position: "absolute",
    zIndex: -1,
  },
  background: {
    minWidth: "100%",
    minHeight: "100%",
    display: "block",
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
});
