import React from 'react';
import './App.css';
import { makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import ProtectedRoute from './components/authentication/ProtectedRoute';
import Notifications from './components/layout/Notifications';


// My Components
import Header from './components/layout/Header';
import MenuContainer from './components/layout/MenuContainer';
import { DetailContract, FormAddContract, FormEditContract, ListContracts } from './components/fromKnack/Contract'
import { DetailClass, FormAddClass, FormEditClass, ListClasses } from './components/fromKnack/Class'
import { DetailClassSession, FormAddClassSession, FormEditClassSession, ListClassSessions } from './components/fromKnack/ClassSession'
import { FormEditEvaluation, ListEvaluations } from './components/fromKnack/Evaluations'
import { DetailInstructor, FormAddInstructor, FormEditInstructor, ListInstructors } from './components/fromKnack/Instructor';
import { DetailInternship, FormAddInternship, FormEditInternship, ListInternships } from './components/fromKnack/Internship';
import { DetailPartner, FormAddPartner, FormEditPartner, ListPartners } from './components/fromKnack/managementObjects.js/Partner';
import { DetailStudent, FormAddStudent, FormEditStudent, ListStudents } from './components/fromKnack/Student';

// Management Menu
import { DetailCenter, FormAddCenter, FormEditCenter, ListCenters } from './components/fromKnack/managementObjects.js/Centers'
import { DetailInstallation, FormAddInstallation, FormEditInstallation, ListInstallations } from './components/fromKnack/managementObjects.js/Installations'
import { DetailAgent, FormAddAgent, FormEditAgent, ListAgents } from './components/fromKnack/managementObjects.js/Agents'
import { DetailPeople, FormAddPeople, FormEditPeople, ListPeoples } from './components/fromKnack/managementObjects.js/People'
import { DetailPedagogicOrientator, FormAddPedagogicOrientator, FormEditPedagogicOrientator, ListPedagogicOrientators } from './components/fromKnack/managementObjects.js/PedagogicOrientator'
import { DetailCourse, FormAddCourse, FormEditCourse, ListCourses } from './components/fromKnack/managementObjects.js/Courses'
import { DetailModule, FormAddModule, FormEditModule, ListModules } from './components/fromKnack/managementObjects.js/Modules'
import { DetailSession, FormAddSession, FormEditSession, ListSessions } from './components/fromKnack/managementObjects.js/Sessions'
import { DetailEmployee, FormAddEmployee, FormEditEmployee, ListEmployees } from './components/fromKnack/managementObjects.js/Employee'
import { DetailDocument, FormAddDocument, FormEditDocumment, ListDocument } from './components/fromKnack/managementObjects.js/Document';

// Configurations Menu
import { FormAddProfession, FormEditProfession, ListProfessions } from './components/fromKnack/configObjects/Professions'
import { FormAddEducationLevel, FormEditEducationLevel, ListEducationLevels } from './components/fromKnack/configObjects/EducationLevels'
import { FormAddTeachingArea, FormEditTeachingArea, ListTeachingAreas } from './components/fromKnack/configObjects/TeachingAreas'
import { FormAddEmployeeJob, FormEditEmployeeJob, ListEmployeeJobs } from './components/fromKnack/configObjects/EmployeeJobs'
import { FormAddPeopleJob, FormEditPeopleJob, ListPeopleJobs } from './components/fromKnack/configObjects/PeopleJobs'
import { FormAddPartnersGroup, FormEditPartnersGroup, ListPartnersGroups } from './components/fromKnack/configObjects/PartnersGroups'
import { FormAddTypesOfPartnership, FormEditTypesOfPartnership, ListTypesOfPartnerships } from './components/fromKnack/configObjects/TypesOfPartnership'
import { FormAddTemplate, FormEditTemplate, ListTemplates, DetailTemplate } from './components/fromKnack/configObjects/Templates'
import LoginPage from './components/authentication/LoginPage/LoginPage'
import { useClearStorageAfterChanges } from './utils/useClearStorageAfterDeploy';
import { FormEditPedagogicSupervision } from './components/fromKnack/PedagogicSupervision';
import UnderMaintenance from './components/layout/UnderMaintenance';
import ErrorPage from './components/layout/ErrorPage'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { FormEditFormacaoModularContrato } from './components/fromKnack/FormacaoModularContrato.js';
import { FormEditFormacaoModularTurma } from './components/fromKnack/FormacaoModularTurma';
import { ListQualificaCenters, DetailQualificaCenter, FormAddQualificaCenter, FormEditQualificaCenter } from './components/fromKnack/configObjects/QualificaCenters.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: "100vh"
  }
}));

const NotUnderMaintenance = ({ children }) => {
  const history = useHistory()
  const underMaintenance = process.env.REACT_APP_UNDER_MAINTENANCE?.toString() === 'true'

  if (underMaintenance) {
    history.replace({
      pathname: '/manutencao',
      search: "",
    });
    return null
  }
  return children
}

const App = () => {
  const classes = useStyles();
  useClearStorageAfterChanges();

  return (
    <Router>
      <CssBaseline />
      <div className={classes.root}>
        <Route path="/manutencao" exact component={UnderMaintenance} />
        <Route path="/erro" component={ErrorPage} />
        <NotUnderMaintenance>
          <Route component={Header} />
          <Route component={MenuContainer} />
          <Notifications />
          <Switch>
            <Route path={["/login", "/"]} exact component={LoginPage} />
            <ProtectedRoute path="/alunos" exact component={ListStudents} />
            <ProtectedRoute path="/alunos/adicionar-aluno" exact component={FormAddStudent} />
            <ProtectedRoute path="/alunos/editar-aluno/:id" component={FormEditStudent} />
            <ProtectedRoute path="/alunos/:id" component={DetailStudent} />

            <ProtectedRoute path="/contratos/:contractId/formacao-modular-contrato/editar-formacao-modular-contrato/:id" component={FormEditFormacaoModularContrato} />

            <ProtectedRoute path="/contratos" exact component={ListContracts} />
            <ProtectedRoute path="/contratos/adicionar-contrato" exact component={FormAddContract} />
            <ProtectedRoute path="/contratos/editar-contrato/:id" component={FormEditContract} />
            <ProtectedRoute path="/contratos/:id" component={DetailContract} />

            <ProtectedRoute path="/turmas/:classId/formacao-modular/editar-formacao-modular/:id" component={FormEditFormacaoModularTurma} />
            <ProtectedRoute path="/turmas/:classId/acompanhamento-pedagogico/editar-acompanhamento-pedagogico/:id" component={FormEditPedagogicSupervision} />

            <ProtectedRoute path="/turmas" exact component={ListClasses} />
            <ProtectedRoute path="/turmas/adicionar-turma" exact component={FormAddClass} />
            <ProtectedRoute path="/turmas/editar-turma/:id" component={FormEditClass} />
            <ProtectedRoute path="/turmas/:id" component={DetailClass} />


            <ProtectedRoute path="/aulas" exact component={ListClassSessions} />
            <ProtectedRoute path="/aulas/adicionar-aula" exact component={FormAddClassSession} />
            <ProtectedRoute path="/aulas/editar-aula/:id" component={FormEditClassSession} />
            <ProtectedRoute path="/aulas/:id" component={DetailClassSession} />

            <ProtectedRoute path="/avaliacoes" exact component={ListEvaluations} />
            <ProtectedRoute path="/avaliacoes/editar-avaliacao/:id" component={FormEditEvaluation} />

            <ProtectedRoute path="/estagios" exact component={ListInternships} />
            <ProtectedRoute path="/estagios/adicionar-estagio" exact component={FormAddInternship} />
            <ProtectedRoute path="/estagios/editar-estagio/:id" component={FormEditInternship} />
            <ProtectedRoute path="/estagios/:id" component={DetailInternship} />

            <ProtectedRoute path="/formadores" exact component={ListInstructors} />
            <ProtectedRoute path="/formadores/adicionar-formador" exact component={FormAddInstructor} />
            <ProtectedRoute path="/formadores/editar-formador/:id" component={FormEditInstructor} />
            <ProtectedRoute path="/formadores/:id" component={DetailInstructor} />

            {/* <ProtectedRoute path="/comunicacao-seguros" exact component={ListInsurances} /> */}

            {/* Management Menu */}
            <ProtectedRoute path="/centros" exact component={ListCenters} />
            <ProtectedRoute path="/centros/adicionar-centro" exact component={FormAddCenter} />
            <ProtectedRoute path="/centros/editar-centro/:id" exact component={FormEditCenter} />
            <ProtectedRoute path="/centros/:id" component={DetailCenter} />

            <ProtectedRoute path="/instalacoes" exact component={ListInstallations} />
            <ProtectedRoute path="/instalacoes/adicionar-instalacao" exact component={FormAddInstallation} />
            <ProtectedRoute path="/instalacoes/editar-instalacao/:id" exact component={FormEditInstallation} />
            <ProtectedRoute path="/instalacoes/:id" component={DetailInstallation} />

            <ProtectedRoute path="/agentes" exact component={ListAgents} />
            <ProtectedRoute path="/agentes/adicionar-agente" exact component={FormAddAgent} />
            <ProtectedRoute path="/agentes/editar-agente/:id" exact component={FormEditAgent} />
            <ProtectedRoute path="/agentes/:id" component={DetailAgent} />

            <ProtectedRoute path="/pessoas" exact component={ListPeoples} />
            <ProtectedRoute path="/pessoas/adicionar-pessoa" exact component={FormAddPeople} />
            <ProtectedRoute path="/pessoas/editar-pessoa/:id" exact component={FormEditPeople} />
            <ProtectedRoute path="/pessoas/:id" component={DetailPeople} />

            <ProtectedRoute path="/orientadores-pedagogicos" exact component={ListPedagogicOrientators} />
            <ProtectedRoute path="/orientadores-pedagogicos/adicionar-orientador-pedagogico" exact component={FormAddPedagogicOrientator} />
            <ProtectedRoute path="/orientadores-pedagogicos/editar-orientador-pedagogico/:id" exact component={FormEditPedagogicOrientator} />
            <ProtectedRoute path="/orientadores-pedagogicos/:id" component={DetailPedagogicOrientator} />

            <ProtectedRoute path="/empresas" exact component={ListPartners} />
            <ProtectedRoute path="/empresas/adicionar-empresa" exact component={FormAddPartner} />
            <ProtectedRoute path="/empresas/editar-empresa/:id" exact component={FormEditPartner} />
            <ProtectedRoute path="/empresas/:id" component={DetailPartner} />

            <ProtectedRoute path="/cursos" exact component={ListCourses} />
            <ProtectedRoute path="/cursos/adicionar-curso" exact component={FormAddCourse} />
            <ProtectedRoute path="/cursos/editar-curso/:id" exact component={FormEditCourse} />
            <ProtectedRoute path="/cursos/:id" component={DetailCourse} />

            <ProtectedRoute path="/modulos" exact component={ListModules} />
            <ProtectedRoute path="/modulos/adicionar-modulo" exact component={FormAddModule} />
            <ProtectedRoute path="/modulos/editar-modulo/:id" exact component={FormEditModule} />
            <ProtectedRoute path="/modulos/:id" component={DetailModule} />

            <ProtectedRoute path="/sessoes" exact component={ListSessions} />
            <ProtectedRoute path="/sessoes/adicionar-sessao" exact component={FormAddSession} />
            <ProtectedRoute path="/sessoes/editar-sessao/:id" exact component={FormEditSession} />
            <ProtectedRoute path="/sessoes/:id" component={DetailSession} />

            <ProtectedRoute path="/colaboradores" exact component={ListEmployees} />
            <ProtectedRoute path="/colaboradores/adicionar-colaborador" exact component={FormAddEmployee} />
            <ProtectedRoute path="/colaboradores/editar-colaborador/:id" exact component={FormEditEmployee} />
            <ProtectedRoute path="/colaboradores/:id" component={DetailEmployee} />

            <ProtectedRoute path="/documentos" exact component={ListDocument} />
            <ProtectedRoute path="/documentos/adicionar-documento" exact component={FormAddDocument} />
            <ProtectedRoute path="/documentos/editar-documento/:id" exact component={FormEditDocumment} />
            <ProtectedRoute path="/documentos/:id" component={DetailDocument} />

            {/* Configurations Menu */}
            <ProtectedRoute path="/profissoes" exact component={ListProfessions} />
            <ProtectedRoute path="/profissoes/adicionar-profissao" exact component={FormAddProfession} />
            <ProtectedRoute path="/profissoes/editar-profissao/:id" exact component={FormEditProfession} />

            <ProtectedRoute path="/habilitacoes-literarias" exact component={ListEducationLevels} />
            <ProtectedRoute path="/habilitacoes-literarias/adicionar-habilitacoes-literarias" exact component={FormAddEducationLevel} />
            <ProtectedRoute path="/habilitacoes-literarias/editar-habilitacoes-literarias/:id" exact component={FormEditEducationLevel} />

            <ProtectedRoute path="/areas-formacao" exact component={ListTeachingAreas} />
            <ProtectedRoute path="/areas-formacao/adicionar-area-formacao" exact component={FormAddTeachingArea} />
            <ProtectedRoute path="/areas-formacao/editar-area-formacao/:id" exact component={FormEditTeachingArea} />

            <ProtectedRoute path="/funcoes-colaborador" exact component={ListEmployeeJobs} />
            <ProtectedRoute path="/funcoes-colaborador/adicionar-funcao-colaborador" exact component={FormAddEmployeeJob} />
            <ProtectedRoute path="/funcoes-colaborador/editar-funcao-colaborador/:id" exact component={FormEditEmployeeJob} />

            <ProtectedRoute path="/funcoes-pessoa" exact component={ListPeopleJobs} />
            <ProtectedRoute path="/funcoes-pessoa/adicionar-funcao-pessoa" exact component={FormAddPeopleJob} />
            <ProtectedRoute path="/funcoes-pessoa/editar-funcao-pessoa/:id" exact component={FormEditPeopleJob} />

            <ProtectedRoute path="/grupos-parceiros" exact component={ListPartnersGroups} />
            <ProtectedRoute path="/grupos-parceiros/adicionar-grupo-parceiro" exact component={FormAddPartnersGroup} />
            <ProtectedRoute path="/grupos-parceiros/editar-grupo-parceiro/:id" exact component={FormEditPartnersGroup} />

            <ProtectedRoute path="/tipos-parceria" exact component={ListTypesOfPartnerships} />
            <ProtectedRoute path="/tipos-parceria/adicionar-tipo-parceria" exact component={FormAddTypesOfPartnership} />
            <ProtectedRoute path="/tipos-parceria/editar-tipo-parceria/:id" exact component={FormEditTypesOfPartnership} />

            <ProtectedRoute path="/templates" exact component={ListTemplates} />
            <ProtectedRoute path="/templates/adicionar-template" exact component={FormAddTemplate} />
            <ProtectedRoute path="/templates/editar-template/:id" exact component={FormEditTemplate} />
            <ProtectedRoute path="/templates/:id" component={DetailTemplate} />

            <ProtectedRoute path="/centros-qualifica" exact component={ListQualificaCenters} />
            <ProtectedRoute path="/centros-qualifica/adicionar-centro-qualifica" exact component={FormAddQualificaCenter} />
            <ProtectedRoute path="/centros-qualifica/editar-centro-qualifica/:id" exact component={FormEditQualificaCenter} />
            <ProtectedRoute path="/centros-qualifica/:id" component={DetailQualificaCenter} />

            <Redirect to="/erro" />
          </Switch>
        </NotUnderMaintenance>
        {/* <Route path="*" component={ErrorPage} /> */}
      </div>
    </Router>
  );
}

export default App;