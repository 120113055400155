import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { customActionsPopUps } from "../../../../redux/actions/customActionsPopUps";
import { setCustomPopUpOpen } from "../../../../redux/actions/popUps";
import SelectBoxField from "../../forms/SelectBoxField";
import TalentoButton from "../../TalentoButton";
import { Contrato } from "../../../../utils/knackKeys";

const useStyles = makeStyles((theme) => ({
  paperFullWidth: {
    overflowY: "visible",
  },
  dialogContentRoot: {
    overflowY: "visible",
  },
  submitButton: {
    margin: theme.spacing(2),
  },
}));

const PopUpForMassChangingContractStatus = (props) => {
  const { gridConfig, gridApi, isCustomPopUpOpen, auth } = props;
  const methods = useForm({ mode: "onTouched" });
  const { handleSubmit, control } = methods;

  const classes = useStyles();
  const [field, setField] = useState();

  useEffect(() => {
    setField(gridConfig?.find((element) => element.key === "field_64"));
  }, [gridConfig]);

  const onSubmit = (formData) => {
    if (formData[field.key]) {
      props.customActionsPopUps("changeStudentsContractStatus", gridApi, {
        contractStatus: formData[field.key],
        userId: auth.userId,
      });
      props.setCustomPopUpOpen(false);
    }
  };

  if (!gridApi || !gridConfig) return null

  return (
    <Dialog
      open={isCustomPopUpOpen}
      maxWidth="xs"
      fullWidth={true}
      classes={{ paperFullWidth: classes.paperFullWidth }}
    >
      <Grid
        container
        style={{ padding: 10, display: "flex", justifyContent: "center" }}
      >
        <DialogTitle>
          <Typography>
            Indique o Estado do Contrato para os alunos selecionados
          </Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          <Grid item xs={12}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TableContainer>
                  <Table aria-label="simple tabel">
                    <TableBody>
                      {field ? (
                        <SelectBoxField
                          control={control}
                          field={field}
                          object={Contrato.object}
                          required={true}
                        />
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <Grid item xs={12}>
                {isAllDataCorrect === false ? (
                  <Typography
                    color="error"
                    style={{ marginTop: 20, textAlign: "center" }}
                  >
                    Por favor selecione uma opção.
                  </Typography>
                ) : null}
              </Grid> */}
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  <TalentoButton
                    onClick={() => props.setCustomPopUpOpen(false)}
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 10 }}
                  >
                    Cancelar
                  </TalentoButton>
                  <TalentoButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    Executar
                  </TalentoButton>
                </Grid>
              </form>
            </FormProvider>
          </Grid>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  customActionsPopUps,
  setCustomPopUpOpen,
})(PopUpForMassChangingContractStatus);
