import { remindSignatureWebhook, sendContractToSignWebhook, sendContractToZohoSignWebhook } from "../../utils/webhooks";
import callWebhook from "./api/callWebhook";
import { Contrato } from "../../utils/knackKeys";
import { dbApiCall } from "./api";
import { GET_ONE, METHODS } from "./knack/types";
import notify from "./helper";
import { ERROR, SUCCESS, WARNING } from "./notifications";
import { OPEN_REASON_POPUP } from "../constants/customActions";
import { IS_CUSTOM_POPUP_OPEN } from "../constants/popUps";
import { EDIT_RECORD } from "../constants/dataApiCalls";

export const createContractToSign = (id, userId, record) => async (dispatch) => {
  const confirm = window.confirm("Tem a certeza que pretende começar um novo ciclo de assinatura?");
  if (!confirm) return;

  try {
    notify({
      dispatch, type: WARNING, message: 'A gerar contrato...'
    })
    const response = await callWebhook({
      webhook: sendContractToZohoSignWebhook,
      body: { id, user_id: userId }
    });
    if (response.data === "Accepted") throw new Error('O processo falhou a meio da execução')
    notify({
      dispatch, type: SUCCESS,
      message: 'O Contrato foi enviado para o seu email. Depois da sua aprovação será enviado ao Formando'
    })
    dispatch({ type: EDIT_RECORD, payload: record });
  } catch (error) {
    return notify({
      dispatch,
      type: ERROR,
      message: `Ocorreu um erro: ${error.data || error?.response?.data}`,
    });
  }
};

export const remindSignature = (id, userId) => async (dispatch) => {
  const confirm = window.confirm("Será enviado um email ao seguinte interveniente do fluxo para prosseguir com a assinatura. Deseja continuar?");
  if (!confirm) return;

  try {
    notify({
      dispatch, type: WARNING, message: 'Enviando email...'
    })
    const response = await callWebhook({
      webhook: remindSignatureWebhook,
      body: { id, user_id: userId }
    });
    if (response.data === "Accepted") throw new Error('O processo falhou a meio da execução')
    const sentTo = response?.data?.sentTo
    notify({
      dispatch, type: SUCCESS,
      message: `O email foi enviado para: ${sentTo || 'alguém'}`
    })
  } catch (error) {
    return notify({
      dispatch,
      type: ERROR,
      message: `Ocorreu um erro: ${error.data || error?.response?.data}`,
    });
  }
};

export const cancelSignature = () => async (dispatch) => {
  dispatch({
    type: OPEN_REASON_POPUP,
    payload: {
      type: "popup",
      componentName: "PopUpCancelReason"
    },
  });
  dispatch({ type: IS_CUSTOM_POPUP_OPEN, payload: true });
};
