import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Controller, useFormContext } from "react-hook-form";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { requiredErrorMessage } from "../../../../utils/errors";
import validate from "./validate";

import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import dayjs from "dayjs";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import pt from "date-fns/locale/pt";
moment.locale("pt");

// const MAX_DATE = dayjs("2100-12-31");
// const MIN_DATE = dayjs("1900-01-01");

const useStyle = (fromSearch) =>
  makeStyles(() => ({
    datePicker: {
      width: fromSearch ? "300px" : "100%",
    },
  }))();

const TextFieldSlot = (props) => {
  return <TextField {...props} />;
};

const DatePickerField = (props) => {
  const {
    field: fieldProps,
    recordValues,
    control,
    disabled,
    filterName,
    fromSearch,
    disableEnforceFocus = false,
  } = props;
  const classes = useStyle(fromSearch);
  const { getValues, trigger, setValue } = useFormContext();

  const [cleared, setCleared] = useState(false);

  const name = filterName || fieldProps.key;

  useEffect(() => {
    if (!cleared) return;
    trigger(name);
    const timeout = setTimeout(() => setCleared(false), 100);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleared]);

  const Wrapper = ({ children }) =>
    fromSearch ? children : <TableRow>{children}</TableRow>;

  const required = !!filterName ? false : fieldProps.required;
  const rules = {
    required: { value: required, message: requiredErrorMessage },
    validate: validate({ fieldKey: fieldProps.key, getValues }),
  };

  const handleOnChange = ({ value, onChange }) => {
    setValue(name, value, {
      shouldTouch: false,
      shouldDirty: false,
      shouldValidate: false,
    });
    // onChange(value);
  };

  const handleOnCloseDialog = () => trigger(name);

  const handleOnBlur = () => trigger(name);

  const defaultValue = !!recordValues 
  ? moment(
    moment.utc(recordValues).format("DD MMM, YYYY"), // Garante que a string da data esteja correta
    "DD MMM, YYYY"
  ).toDate()
  : null;

  return (
    <Wrapper>
      <TableCell>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          rules={rules}
          render={({
            field: { ref, onChange, value, onBlur, ...rest },
            fieldState,
          }) => {
            return (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={pt}
              >
                <DatePicker
                  {...rest}
                  inputRef={ref}
                  disabled={disabled}
                  className={classes.datePicker}
                  label={fieldProps.name}
                  format="dd-MM-yyyy"
                  size="small"
                  value={value || null}
                  onClose={handleOnCloseDialog}
                  onChange={(value) => handleOnChange({ value, onChange })}
                  slots={{ textField: TextFieldSlot }}
                  slotProps={{
                    textField: {
                      error: !!fieldState.error,
                      helperText: fieldState.error?.message,
                      required,
                      onBlur: handleOnBlur,
                      size: "small",
                    },
                    field: {
                      clearable: true,
                      onClear: () => setCleared(true),
                    },
                    desktopTrapFocus: { disableEnforceFocus },
                  }}
                />
              </LocalizationProvider>
            );
          }}
        />
      </TableCell>
    </Wrapper>
  );
};

export default DatePickerField;
