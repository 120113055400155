import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import {
  userHasPermissions,
  userHasPermissionsToCreateDigitalDocument,
  userHasPermissionsToCreateDocument,
} from "../../../../utils/userPermissions";
import { connect } from "react-redux";
import DigitalDocumentsArea from "./DigitalDocuments";
import CreateDocumentsArea from "./CreateDocuments";
import { Contrato } from "../../../../utils/knackKeys";
import { noContract, accepted, expired, inAcceptance, rejected, signed, canceled, noCancel } from "../../../../constants/strings";

/**
 * BOOLEAN VALUES FROM THIS COMPONENT ARE ALL WRONG (they are working but it's unintelligible)
 */

const DocumentCreationArea = ({
  userPermissions,
  documents,
  documentsToSign,
  // COMING FROM REDUX
  auth,
  record,
  document,
  formSubmission
}) => {
  const { userPermissionsField, viewRolesPermissions } = userPermissions;
  const { userId, profile_keys, profileId } = auth;

  const { ESTADO_DESCRITIVO_ASSINATURA_FASES } = Contrato.fields

  const classes = useStyles();
  const [documentLoading, setDocumentLoading] = useState(null);
  const { pathname } = useLocation();
  const [, , id] = pathname.split("/");
  const [message, setMessage] = useState([])
  const [buttons, setButtons] = useState([])

  const canUserCreateDocument = userHasPermissionsToCreateDocument(
    userPermissions.documentsRolesPermissions,
    profile_keys
  );

  const canUserCreateDigitalDocument =
    userHasPermissionsToCreateDigitalDocument(
      userPermissions.digitalDocumentsRolesPermissions,
      profile_keys
    );

  const canUserSeeRecord = userHasPermissions({
    userPermissionsField: record?.[userPermissionsField],
    viewRolesPermissions,
    currentUserId: userId,
    userProfileKeys: profile_keys,
    currentProfileId: profileId,
    objectId: id,
  });

  const displayDigitalDocumentSection =
    !canUserCreateDigitalDocument &&
    canUserSeeRecord &&
    documentsToSign?.length;

  const displayCreateDocumentsArea =
    !canUserCreateDocument && documents?.length;

  const createDocument = async (newDocument) => {
    setDocumentLoading(newDocument.type);
    if (!document || document?.type !== newDocument.type)
      await newDocument.documentFunction(id, userId, record);
  };

  const displayDocumentLink = useMemo(() => {
    if (document) {
      setDocumentLoading(null);
      return document.document;
    }
    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  const signingPhase = record[ESTADO_DESCRITIVO_ASSINATURA_FASES];
  useEffect(() => {
    if (!record) return
    let info = {
      id: 0,
      content: "",
      visibleToProfiles: ["profile_24", "profile_20"],
      allowClose: false
    }

    const changeMessage = (content = "", id) => { info.content = content; info.id = id }

    const allowedActions = documentsToSign?.filter(action => action?.allowedPhases?.includes(signingPhase))
    setButtons(allowedActions)

    switch (signingPhase) {
      case "0": { changeMessage(noContract, 0); break; }
      case "1": { changeMessage(inAcceptance, 1); break; }
      case "2": { changeMessage(accepted, 2); break; }
      case "3": { changeMessage(signed, 3); break; }
      case "4": { changeMessage(rejected, 4); break; }
      case "5": { changeMessage(rejected, 5); break; }
      case "6": { changeMessage(expired, 6); break; }
      case "7": { changeMessage(canceled, 6); break; }
      default: { changeMessage("", 0) }
    }

    if (signingPhase === "2") return setMessage([info, {
      id: 2.5,
      content: noCancel,
      visibleToProfiles: ["profile_24", "profile_20"],
      allowClose: false
    }])

    setMessage([info])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmission, record, signingPhase])

  return (
    <Grid container justifyContent="space-between">
      <CreateDocumentsArea
        displayCreateDocumentsArea={displayCreateDocumentsArea}
        documents={documents}
        createDocument={createDocument}
        documentLoading={documentLoading}
        displayDocumentLink={displayDocumentLink}
        userPermissions={userPermissions}
        auth={auth}
        classes={classes}
        document={document}
      />
      <DigitalDocumentsArea
        message={message}
        documentsToSign={buttons}
        displayDigitalDocumentSection={displayDigitalDocumentSection}
        createDocument={createDocument}
        canUserCreateDigitalDocument={canUserCreateDigitalDocument}
        classes={classes}
        record={record}
      />
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  documentActions: {
    width: "100%",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "0.8rem",
  },
}));

const mapStateToProps = (state) => ({
  auth: state.auth,
  record: state.record,
  document: state.documents,
  formSubmission: state.formSubmission
});

export default connect(mapStateToProps, null)(DocumentCreationArea);
