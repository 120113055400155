import { pdf } from "@react-pdf/renderer";
import { Diploma } from "../../../components/documents/diploma";
import {
  Aluno,
  Avaliacao,
  Contrato,
  Curso,
  Modulos,
  Turma,
} from "../../../utils/knackKeys";
import { uploadFileToS3 } from "../../../utils/uploadFileToS3";
import { createDiplomaWebhook } from "../../../utils/webhooks";
import { PROCESSING_AUTOMATION } from "../../constants/customActions";
import { dbApiCall } from "../api";
import callWebhook from "../api/callWebhook";
import {
  GET_MULTIPLE_FILTERING_CONNECTED,
  GET_ONE,
  METHODS,
} from "../knack/types";
import { getAuthCache } from "../knackconfig";
import { certificateLetter } from "../../../components/documents/cetificateLetter";
// import ReactDOM from "react-dom";
import notify from "../helper";
import { ERROR } from "../notifications";
import sleep from "../../../utils/sleep";

const {
  fields: { NAME, MORADA },
} = Aluno;

const {
  fields: { CURSO: CURSO_TURMA, DATA_FIM },
} = Turma;

const {
  fields: { CARGA_HORARIA, SAIDAS_PROFISSIONAIS, CURSO_TECNICO },
} = Curso;

const {
  fields: { CURSO: CURSO_EM_MODULO },
} = Modulos;

const {
  fields: { ALUNO, CURSO },
} = Contrato;

const {
  fields: { TURMA, ALUNO: ALUNO_AVALIACAO, CONTRATO, NOTA_FINAL },
} = Avaliacao;

const createDiploma = (id, selectedEvaluation) => async (dispatch) => {
  const classScene = Turma.details.scene;
  const classView = Turma.details.view.details;
  const courseScene = Curso.details.scene;
  const courseView = Curso.details.view;
  const evaluationsScene = Avaliacao.details.scene;
  const evaluationsView = Avaliacao.details.view;

  const auth = getAuthCache();
  let errors = [];

  try {

    const classData = await dbApiCall({
      action: METHODS.GET.value,
      type: GET_ONE,
      scene: classScene,
      view: classView,
      id,
      auth,
    });

    const { data: classInfo } = classData;
    const courseId = classInfo[CURSO_TURMA][0].id;

    const courseData = await dbApiCall({
      action: METHODS.GET.value,
      type: GET_ONE,
      scene: courseScene,
      view: courseView,
      id: courseId,
      auth,
    });

    const { data: course } = courseData;
    const isTechnical = course[CURSO_TECNICO]

    if (isTechnical) throw new Error('technical_course')

    const courseModules = await dbApiCall({
      action: METHODS.GET.value,
      type: GET_MULTIPLE_FILTERING_CONNECTED,
      scene: "scene_233",
      view: "view_321",
      id: courseId,
      auth,
      urlParams: { connectedField: CURSO_EM_MODULO },
    });

    const { data: modules } = courseModules;

    const evaluationsAssociatedToClass = await dbApiCall({
      action: METHODS.GET.value,
      type: GET_MULTIPLE_FILTERING_CONNECTED,
      scene: evaluationsScene,
      view: evaluationsView,
      id,
      auth,
      urlParams: { connectedField: TURMA },
    });

    let evaluations = [];

    errors = await Promise.all(evaluationsAssociatedToClass.data.records.map(async (evaluation) => {
      try {
        if (selectedEvaluation !== evaluation.id) return;

        evaluations.push({
          classEndDate: classInfo[DATA_FIM]?.iso_timestamp,
          classCourseName: classInfo[CURSO_TURMA][0]?.identifier,
          courseHours: course[CARGA_HORARIA]?.toString(),
          studentName: evaluation?.[ALUNO_AVALIACAO][0]?.identifier,
          grade: evaluation?.[NOTA_FINAL]?.toString(),
          contractId: evaluation?.[CONTRATO][0]?.id,
          studentId: evaluation?.[ALUNO_AVALIACAO][0]?.id,
          modules: modules?.records,
          jobOpportunities: course?.[SAIDAS_PROFISSIONAIS].toString(),
          evaluationId: evaluation.id,
        });
        await sleep(150);

      } catch (error) {
        console.log("error inside Promise all to get each Contract", error);
        return { error: evaluation.studentName };
      }
    }))

    if (errors.find((el) => !!el)) return errors[0];

    // ReactDOM.render(
    //   <Diploma {...evaluations[0]} />,
    //   document.getElementById("root")
    // );

    errors = await Promise.all(
      evaluations.map(async (evaluation) => {
        try {
          evaluation.document = Diploma(evaluation);
          if (evaluation.document) {
            const blob = await pdf(evaluation.document).toBlob();
            if (blob) {
              const diploma = { extension: "pdf", blob };
              const error = await uploadFileToS3(
                diploma,
                "scene_98",
                "view_261",
                evaluation.contractId
              );
              if (error) throw error;
            }
            await callWebhook({
              webhook: createDiplomaWebhook,
              body: { data: evaluation },
            });
            await sleep(150);
          }
        } catch (error) {
          console.log("error inside Promise all to generate Diplomas", error);
          return { error: evaluation.studentName };
        }
      })
    );
    if (errors.find((el) => !!el)) return errors[0];
  } catch (err) {
    console.log("error=>", err);
    if (err?.message === "technical_course") return { technicalError: true };
    else notify({
      dispatch,
      type: ERROR,
      message: `Algo correu mal, por favor tente de novo. Detalhes: ${err?.response?.data || err?.message}`,
    });
    dispatch({
      type: PROCESSING_AUTOMATION,
      payload: {
        processingStatus: "complete",
      },
    });
  }
};

const createCertificateLetter = (id) => async (dispatch) => {
  const result = await dbApiCall({
    action: METHODS.GET.value,
    type: GET_MULTIPLE_FILTERING_CONNECTED,
    scene: Contrato.list.scene,
    view: Contrato.list.view,
    id,
    urlParams: {
      connectedField: Contrato.fields.TURMA,
    },
  });

  const contractsAssociatedToClass = result.data.records;

  let studentsDataDefault = {
    studentName: "{NOME DO ALUNO}",
    studentStreet: "{RUA DO ALUNO}",
    studentZip: "{CODIGO POSTAL DO ALUNO}",
    studentCity: "{CIDADE DO ALUNO}",
  };

  const newStudentsData = await Promise.all(
    contractsAssociatedToClass.map(async (contract) => {
      if (!contract[ALUNO]?.length) {
        if (contract[CURSO]?.[0])
          studentsDataDefault.courseName = contract[CURSO][0].identifier;
        return studentsDataDefault;
      }

      let studentResponse = await dbApiCall({
        action: METHODS.GET.value,
        type: GET_ONE,
        scene: "scene_29",
        view: "view_31",
        id: contract[ALUNO][0].id,
      });

      const { data: student } = studentResponse;

      let studentData = {
        studentName: student[NAME]
          ? `${student[NAME].first} ${student[NAME].middle} ${student[NAME].last}`
          : "{NOME DO ALUNO}",
        studentStreet: student[MORADA]?.street || "{RUA DO ALUNO}",
        studentZip: student[MORADA]?.zip || "{CODIGO POSTAL DO ALUNO}",
        studentCity: student[MORADA]?.city || "{CIDADE DO ALUNO}",
        courseName: contract[CURSO]?.[0]?.identifier || "{CURSO DO ALUNO}",
      };
      return studentData;
    })
  );
  const document = certificateLetter(newStudentsData);

  dispatch({
    type: "CREATE_CERTIFICATE_LETTER",
    payload: document,
  });
};

export { createDiploma, createCertificateLetter };
