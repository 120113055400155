export const modularCertificateAlert = `
    Denote que, se concluir com sucesso o tipo:
    <ul>
        <li><b>CBA</b> - Serão disponibilizados 3 certificados nos campos <i>C - Certificado</i>, <i>B - Certificado</i> e <i>A - Certificado</i>, à medida que for concluindo as respetivas etapas;</li>
        <li><b>CB</b> - Serão disponibilizados 2 certificados nos campos <i>C - Certificado</i> e <i>B - Certificado</i>, à medida que for concluindo as respetivas etapas;</li>
        <li><b>C</b> - Será disponibilizado 1 certificado no campo <i>C - Certificado</i>, aquando da respetiva conclusão.</li>
    </ul>
`;

export const noContract = `
Antes de iniciar o fluxo de assinatura, <b>REVEJA AS INFORMAÇÕES INTRODUZIDAS</b>.<br/>
Vamos evitar custos adicionais para a Talento 💙
`

export const inAcceptance = `
Contrato <b>Em Aprovação</b><br/><br/>
Caso haja alguma informação mal introduzida, nesta fase:<br/><br/>
<ol>
    <li>Deve fazer as alterações necessárias ao contrato;</li>
    <li>Deve <u>Rejeitar</u> o contrato que recebeu por email para aprovar OU clicar no botão <u>Cancelar Assinatura</u> abaixo.</li>
    <li>Pode começar novamente o fluxo de assinatura.</li>
</ol><br/>
<b>NOTA</b>: esta ação incorre em custos adicionais para a Talento, como tal, pedimos a maior moderação no seu uso.
`

export const accepted = `
Contrato <b>Aprovado, Em Assinatura</b><br/><br/>
Caso haja alguma informação mal introduzida, nesta fase:<br/><br/>
<ol>
    <li>Deve fazer as alterações necessárias ao contrato;</li>
    <li>Deve clicar no botão <u>Cancelar Assinatura</u> abaixo;</li>
    <li>Pode começar novamente o fluxo de assinatura.</li>
</ol><br/>
<b>NOTA</b>: esta ação incorre em custos adicionais para a Talento, como tal, pedimos a maior moderação no seu uso.
`

export const noCancel = `
<b>Caso o formando erre o código 3x, não cancele o contrato</b>. Informe no grupo <b>#problemas formalizações</b> e um funcionário da sede desbloqueará o contrato.
`

export const signed = `
Contrato <b>Assinado</b><br/><br/>
Caso haja alguma informação mal introduzida, nesta fase:<br/><br/>
<ol>
    <li>Deve fazer as alterações necessárias ao contrato;</li>
    <li>Deve comunicar o tema no grupo <u>#problemas formalizações</u>;</li>
    <li>Algum membro da sede poderá reiniciar o processo de assinatura.</li>
</ol><br/>
<b>NOTA</b>: esta ação incorre em custos adicionais para a Talento, como tal, pedimos a maior moderação no seu uso.
`

export const rejected = `
Este contrato foi <b>Rejeitado</b>. Pode recomeçar o fluxo normalmente, caso se aplique.
`

export const expired = `
Este contrato está <b>Expirado</b>. Pode recomeçar o fluxo normalmente, caso se aplique.
`

export const canceled = `
Este contrato foi <b>Cancelado</b>. Pode recomeçar o fluxo normalmente, caso se aplique.
`