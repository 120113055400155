import React from "react";
import _ from "lodash";
import { Button } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";

const TalentoButton = (props) => {
  const { children, onClick, loading = false, debounceTime = 500 } = props;
  const finalProps = _.clone(props);
  delete finalProps.debounceTime;
  delete finalProps.loading;

  const handleClick = _.debounce(
    (event) => {
      event.persist();
      return onClick && onClick(event);
    },
    debounceTime,
    { leading: true, trailing: false }
  );

  if (loading) return (
    <LoadingButton {...finalProps} loading={loading}>
      {children}
    </LoadingButton>
  );

  return (
    <Button {...finalProps} onClick={handleClick}>
      {children}
    </Button>
  );
};

export default TalentoButton;
