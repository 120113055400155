import _ from "lodash";
import XLSX from "xlsx";
import { userCanSeeField } from "../../userPermissions";

const getTotalPages = (response) => {
  const totalRecords = response.totalRecords;
  const totalPages = Math.ceil(totalRecords / 30);
  const roundTotalPages = totalPages >= 1 ? totalPages : 1;

  return roundTotalPages;
};

const createExcel = (data) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "List");
  XLSX.writeFile(wb, "exportacao.xlsb");
};

const prepareDataForExport = (data, gridConfig, profile_keys) => {
  let dataReady = [];
  data.map((item) => {
    let newItem = {};
    gridConfig.forEach((configItem) => {
      let value = _.get(item, configItem.key);

      switch (configItem.type) {
        case "name":
          if (userCanSeeField(configItem.canView, profile_keys, "export")) {
            newItem[
              configItem.name
            ] = `${value.first} ${value.middle} ${value.last}`;
          }
          break;
        case "address":
          if (userCanSeeField(configItem.canView, profile_keys, "export")) {
            newItem[`${configItem.name}-street`] = value?.street;
            newItem[`${configItem.name}-zip`] = value?.zip;
            newItem[`${configItem.name}-city`] = value?.city;
            newItem[`${configItem.name}-country`] = value?.country;
          }
          break;
        case "boolean":
          if (userCanSeeField(configItem.canView, profile_keys)) {
            newItem[configItem.name] = value === true ? "Sim" : "Não";
          }
          break;
        case "rich_text":
          if (userCanSeeField(configItem.canView, profile_keys)) {
            newItem[configItem.name] = value?.replace(/(<([^>]+)>)/gi, "");
          }
          break;
        case "id":
          if (userCanSeeField(configItem.canView, profile_keys)) {
            newItem["id"] = value;
          }
          break;
        case "multiple_choice":
        case "connection":
          if (userCanSeeField(configItem.canView, profile_keys)) {
            if (configItem.type === "multiple_choice") {
              const multiple_choice_values = Array.isArray(value)
                ? value.join()
                : value;
              newItem[configItem.name] = multiple_choice_values;
            } else if (configItem.type === "connection" && value) {
              let fieldValues = [];
              value.map((value) => {
                return fieldValues.push(value.identifier);
              });
              newItem[configItem.name] = fieldValues.join();
            }
          }
          break;
        default:
          if (userCanSeeField(configItem.canView, profile_keys)) {
            newItem[configItem.name] = value;
          }
      }
    });

    return dataReady.push(newItem);
  });

  return dataReady;
};

export { getTotalPages, createExcel, prepareDataForExport };
