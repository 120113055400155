import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";

import Table from "@material-ui/core/Table";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { customActionsPopUps } from "../../../../redux/actions/customActionsPopUps";
import { setCustomPopUpOpen } from "../../../../redux/actions/popUps";

import InputTextField from "../../forms/InputTextField";

import { fetchGridConfig } from "../../../../redux/actions/knackconfig";
import TalentoButton from "../../TalentoButton";
import { Contrato } from "../../../../utils/knackKeys";
import { Typography } from "@mui/material";
import { TableBody } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paperFullWidth: {
        overflowY: "visible"
    },
    dialogContentRoot: {
        overflowY: "visible",
    },
}));

const findField = (formConfig) => {
    let field = null
    formConfig.forEach(groups => {
        const found = groups.fields.find(field => field.key === Contrato.fields.MOTIVO_REJEICAO_CONTRATO)
        if (found) field = found
    })
    return field
}

const PopUpCancelReason = (props) => {
    const {
        id,
        isCustomPopUpOpen,
        customActionsPopUps,
        setCustomPopUpOpen,
        formConfig,
        record,
    } = props;
    const methods = useForm({ mode: "onTouched" });
    const { handleSubmit, control, errors } = methods;
    const [isLoading, setIsLoading] = useState(false)

    const reasonField = findField(formConfig)

    const classes = useStyles();

    const onSubmit = async (formData) => {
        const reason = formData[reasonField.key]
        setIsLoading(true)
        await customActionsPopUps("cancelSignature", null, { reason, contractId: id, record });
        setIsLoading(false)
        setCustomPopUpOpen(false);
    };

    const PopUpHeader = () => (
        <DialogTitle style={{ paddingBottom: 0 }}>
            <Typography fontSize={20} align="center">
                Motivo do cancelamento:
            </Typography>
        </DialogTitle>
    );

    const ActionsMenu = () => (
        <Grid
            item
            xs={12}
            style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
            }}
        >
            <TalentoButton
                onClick={() => setCustomPopUpOpen(false)}
                variant="contained"
                color="primary"
                style={{ marginRight: 180 }}
            >
                Cancelar
            </TalentoButton>
            <TalentoButton variant="contained" color="secondary" type="submit" loading={isLoading}>
                Executar
            </TalentoButton>
        </Grid>
    );

    return (
        <Dialog
            open={isCustomPopUpOpen}
            maxWidth="xs"
            fullWidth={true}
            classes={{ paperFullWidth: classes.paperFullWidth }}
        >
            <Grid
                container
                style={{
                    paddingBottom: 10, display: "flex", justifyContent: "center"
                }}
            >
                <PopUpHeader />
                <DialogContent classes={{ root: classes.dialogContentRoot }}>
                    <Grid item>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                <Table>
                                    <TableBody>
                                        <InputTextField
                                            errors={errors}
                                            control={control}
                                            field={reasonField}
                                            required
                                            style={{ paddingRight: 0, paddingLeft: 0 }}
                                        />
                                    </TableBody>
                                </Table>
                                <ActionsMenu />
                            </form>
                        </FormProvider>
                    </Grid>
                </DialogContent>
            </Grid>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({ formConfig: state.formConfig })

export default connect(mapStateToProps, {
    customActionsPopUps,
    setCustomPopUpOpen,
    fetchGridConfig,
})(PopUpCancelReason);
