import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, Toolbar, Grid } from "@material-ui/core";
import {
  cleanRecord,
  fetchRecord,
} from "../../../redux/actions/api/dataApiCalls";
import { clearErrors } from "../../../redux/actions/errors";
import { changeTab, clearTab, getTabs } from "../../../redux/actions/layout";
import { clearDocument } from "../../../redux/actions/documents";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import DetailHeader from "./DetailHeader";
import TabsSelection from "./TabsSelection";
import RenderTab from "./RenderTab";
import DocumentCreationArea from "./DocumentCreationArea";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CustomPopUp from "../customComponents/PopUps/CustomPopUp";

const DetailView = (props) => {
  const classes = useStyles();
  const {
    objectLabelSingular,
    objectLabelPlural,
    id,
    object,
    scene,
    view,
    sceneEdit,
    viewEdit,
    documents,
    documentsToSign,
    icon,
    userPermissions,
    tabs,
    deletable,
    uploadToFolderField = "",
    // PROPS FROM REDUX
    tab,
    getTabs,
    fetchedTabs,
    record,
    detailHeaderFields,
    clearTab,
  } = props;

  const [isTabDisabled, setIsTabDisabled] = useState(true);
  const location = useLocation();
  const currentTab = location.search.split("?active_tab=")?.[1];

  useEffect(() => {
    (() => {
      props.clearErrors();

      if (detailHeaderFields) getTabs({ tabs, record: detailHeaderFields });
      if (detailHeaderFields && detailHeaderFields.id === id)
        setIsTabDisabled(false);
    })();

    return () => {
      props.clearErrors();
      if (currentTab) {
        const currentSelectedTab = fetchedTabs.find(
          (el) => el.params.slug === currentTab
        );
        // eslint-disable-next-line eqeqeq
        if (currentSelectedTab?.position != tab) {
          props.changeTab(Number(currentSelectedTab?.position));
        }
      } else clearTab();
      props.clearDocument();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailHeaderFields]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Toolbar />
      <ErrorBoundary>
        <Grid container spacing={1} style={{ padding: "0 10px" }}>
          <Grid
            container
            spacing={0}
            style={{
              // position: "sticky",
              top: "63px",
              // zIndex: 2,
              backgroundColor: "#F9F9F9",
              padding: "12px 4px 0 4px",
              marginBottom: 5,
            }}
          >
            <DetailHeader
              objectLabelPlural={objectLabelPlural}
              objectLabelSingular={objectLabelSingular}
              id={id}
              icon={icon}
              record={record}
              object={object}
              scene={scene}
              view={view}
              userPermissions={userPermissions}
              deletable={deletable}
            />
            <TabsSelection
              id={id}
              isTabDisabled={isTabDisabled}
              scene={scene}
              view={view}
            />
          </Grid>
          <RenderTab
            object={object}
            scene={scene}
            sceneEdit={sceneEdit}
            view={view}
            viewEdit={viewEdit}
            userPermissions={userPermissions}
            uploadToFolderField={uploadToFolderField}
          />
          <DocumentCreationArea
            documents={documents}
            documentsToSign={documentsToSign}
            userPermissions={userPermissions}
          />
        </Grid>
      </ErrorBoundary>
      <CustomPopUp id={id} record={record}/>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: "inherit",
  },
  documentActions: {
    width: "100%",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "0.8rem",
  },
}));

const mapStateToProps = (state) => {
  return {
    record: state.record,
    tab: state.layout.tab,
    document: state.documents,
    auth: state.auth,
    detailHeaderFields: state.detailHeaderFields,
    fetchedTabs: state.layout.tabs,
  };
};

export default connect(mapStateToProps, {
  clearErrors,
  cleanRecord,
  clearDocument,
  fetchRecord,
  changeTab,
  clearTab,
  getTabs,
})(DetailView);
