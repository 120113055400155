/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
import React from "react";
import _ from "lodash";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";

import Avatar from "@material-ui/core/Avatar";
import LaunchIcon from "@material-ui/icons/Launch";
import {
  KnackAndFrontEndRelMainMenu,
  KnackAndFrontEndRelConfigMenu,
} from "./KnackAndFrontEndRel";
import { userCanClickInConnectedFields } from "./userPermissions";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/pt";
import TalentoButton from "../components/layout/TalentoButton";
import { Contrato, Estagio } from "./knackKeys";
import { ERROR, INFO } from "../redux/actions/notifications";
import { Chip } from "@mui/material";
moment.locale("pt");

export const renderFieldValue = ({
  field,
  record,
  layout,
  profileKeys = null,
}) => {
  if (typeof field === "undefined") return null;
  const value = _.pick(record, field.key)[field.key];

  switch (field.type) {
    case "name":
      if (typeof value === "undefined") return "";
      return `${value?.first || ""}${
        value?.middle ? ` ${value?.middle}` : ""
      } ${value?.last || ""}`;

    case "number":
    case "currency":
    case "sum":
    case "short_text":
    case "concatenation": {
      const styles = customDisplayFinantialSituation({
        field: field.key,
        layout,
        value,
      });
      if (!styles) return value || "";
      return (
        <Chip
          component="span"
          color={styles.backgroundColor}
          style={{ color: styles.color }}
          label={value}
        />
      );
    }
    case "password":
      return value || "";

    case "average":
      return parseFloat(value).toFixed(2) || "";

    case "rich_text":
    case "paragraph_text":
      return value?.replace(/(<([^>]+)>)/gi, "") || "";
    case "boolean":
      if (value === true) return <ThumbUpIcon style={{ color: "#14A48B" }} />;
      return <ThumbDownIcon style={{ color: "#FF694D" }} />;

    case "link":
      return (
        <a href={value?.url || ""} target="_blank" rel="noreferrer">
          {value?.url ? "Abrir" : ""}
        </a>
      );

    case "phone":
      return value?.formatted || "";

    case "email":
      return value?.email || "";

    case "date_time":
      if (typeof value === "undefined") return "";
      const timestampObject = value?.unix_timestamp;
    
      if (timestampObject) {
        return moment.utc(timestampObject).format("DD MMM, YYYY"); // Força UTC
      }
      return "";
      

    case "multiple_choice":
    case "user_roles":
      const styles = customDisplayFinantialSituation({
        field: field.key,
        layout,
        value,
      });
      if (!styles) return value;
      return (
        <Chip
          component="span"
          color={styles.backgroundColor}
          style={{ color: styles.color }}
          label={value}
        />
      );
    case "image": // its being used only to show, if needs upload there is component UploadImage
      if (typeof value === "undefined") return "";
      return <Avatar src={value.thumb_url} />;

    case "equation":
      if (typeof value === "undefined" || field.format.equation_type !== "date")
        return "";
      if (
        !value?.unix_timestamp ||
        !moment(value?.unix_timestamp).format("DD MM YYYY hh:mm:ss")
      )
        return Math.floor(value / 31536000);
      return moment(value.unix_timestamp).format("DD MMM, YYYY");
    // return Math.floor(value / 31536000);

    case "connection":
      if (typeof value === "undefined" || !Array.isArray(value)) return "";
      let connectedRecords = [];
      value.forEach((connectedRecord) => {
        KnackAndFrontEndRelMainMenu.forEach((item) => {
          getConnectedFieldUrl(item, field, connectedRecord);
        });
        KnackAndFrontEndRelConfigMenu.management.forEach((item) => {
          getConnectedFieldUrl(item, field, connectedRecord);
        });
        KnackAndFrontEndRelConfigMenu.configurations.forEach((item) => {
          getConnectedFieldUrl(item, field, connectedRecord);
        });
        connectedRecords.push(connectedRecord);
      });

      if (layout === "header")
        return value.map((connectedRecord) => connectedRecord.identifier);

      return (
        // eslint-disable-next-line array-callback-return
        connectedRecords.map((connectedRecord) => {
          if (connectedRecord?.url) {
            return (
              <React.Fragment key={connectedRecord.id}>
                <TalentoButton
                  key={connectedRecord.id}
                  component={Link}
                  to={connectedRecord.url}
                  variant="contained"
                  color="inherit"
                  size="small"
                  style={{ margin: 5 }}
                  disabled={userCanClickInConnectedFields(
                    field.canClickConnected,
                    profileKeys
                  )}
                >
                  {connectedRecord.identifier}
                </TalentoButton>
                <TalentoButton
                  target="_blank"
                  href={connectedRecord.url}
                  disabled={userCanClickInConnectedFields(
                    field.canClickConnected,
                    profileKeys
                  )}
                >
                  <LaunchIcon />
                </TalentoButton>
              </React.Fragment>
            );
          }
          if (connectedRecord) {
            return (
              <Chip
                key={connectedRecord.id}
                label={connectedRecord.identifier}
                style={{ margin: 5 }}
                variant="outlined"
              />
            );
          }
        })
      );
  }
};

const getConnectedFieldUrl = (item, field, connectedRecord) => {
  if (item.object === field.relationship.object && item.withLink === true) {
    connectedRecord.url = `${item.url}/${connectedRecord.id}`;
  }
};

const customDisplayFinantialSituation = ({ field, layout, value }) => {
  if (layout !== "header") return;
  const multipleChoiceFields = [
    Contrato.fields.SITUACAO_FINANCEIRA,
    Estagio.fields.SITUACAO_FINANCEIRA,
  ];
  if (!multipleChoiceFields.includes(field)) return;

  const option = Contrato.fieldsOptions.SITUACAO_FINANCEIRA.find(
    (el) => el.value === value
  );
  if (!option) return;
  if (option.level === ERROR)
    return { backgroundColor: "error", color: "white" };
  if (option.level === INFO)
    return { backgroundColor: "default", color: "black" };
  return;
};
