import { Grid, Paper, Typography } from "@material-ui/core";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import React from "react";
import { userHasPermissionsToCreateDocument } from "../../../../utils/userPermissions";
import TalentoButton from "../../TalentoButton";

const CreateDocumentsArea = ({
  displayCreateDocumentsArea,
  document,
  documents,
  classes,
  createDocument,
  documentLoading,
  displayDocumentLink,
  userPermissions,
  auth,
}) => {
  const isUserBlockedFromCreatingDocument = userHasPermissionsToCreateDocument(
    // Nome de caca
    userPermissions.documentsRolesPermissions,
    auth.profile_keys
  );

  if (!displayCreateDocumentsArea) return null;
  return (
    <Paper elevation={3} className={classes.documentActions}>
      <Grid style={{ margin: 10 }}>
        <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
          Criação de documentos
        </Typography>
        <div>
          {documents.map((doc) => {
            return (
              <div key={doc.type} className={classes.buttonWrapper}>
                {doc.type === documentLoading ? (
                  <TalentoButton variant="contained" disabled={true}>
                    {doc.title}
                  </TalentoButton>
                ) : (
                  <TalentoButton
                    variant="contained"
                    color="secondary"
                    onClick={() => createDocument(doc)}
                    disabled={isUserBlockedFromCreatingDocument}
                    style={{ width: '20rem' }}
                  >
                    {doc.title}
                  </TalentoButton>
                )}
                {document?.type === doc?.type ? (
                  <PDFDownloadLink
                    style={{ marginLeft: 20 }}
                    document={displayDocumentLink}
                    fileName={document.fileName}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        "..."
                      ) : (
                        <CloudDownloadIcon
                          fontSize="large"
                          style={{ color: "#FF694D" }}
                        />
                      )
                    }
                  </PDFDownloadLink>
                ) : null}
              </div>
            );
          })}
        </div>
      </Grid>
    </Paper>
  );
};

export default CreateDocumentsArea;
