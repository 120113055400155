import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { requiredErrorMessage } from "../../../../utils/errors";
import removeHtmlTags from "../../../../utils/removeHTMLTags";
import validate from "./validate";
import handleOnBlur from "./handleOnBlur";

const useStyle = makeStyles((theme) => ({
  inputField: {
    width: "100%",
  },
}));

const InputTextField = ({
  field: fieldProps,
  recordValues,
  disabled,
  control,
  required = undefined,
  style
}) => {
  const classes = useStyle();
  const [helper, setHelper] = useState("");
  const { trigger, getValues, getFieldState, formState } = useFormContext();

  const multiline =
    fieldProps.type === "rich_text" ||
    fieldProps.type === "paragraph_text" ||
    false;

  const handleOnChange = ({ event, onChange }) => {
    setHelper("");
    return onChange(event);
  };

  const requiredValue = required || fieldProps.required;

  const getValue = (value) => {
    const final = fieldProps.name.includes("NIF")
      ? !!value
        ? value.toString().trim()
        : ""
      : value;
    return final;
  };

  const getHelperText = (error) =>
    error?.message || helper || removeHtmlTags(fieldProps.meta?.description);

  const rules = {
    required: { value: requiredValue, message: requiredErrorMessage },
    validate: validate({
      fieldProps,
      setHelper,
      helper,
      trigger,
      getValues,
      getFieldState,
      formState,
    }),
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row" style={{ width: "100%", ...style }}>
        <Controller
          control={control}
          name={fieldProps.key}
          defaultValue={recordValues?.url || recordValues || ""}
          rules={rules}
          render={({
            field: { value, onBlur, onChange, ...rest },
            fieldState: { error },
          }) => {
            return (
              <TextField
                {...rest}
                value={getValue(value)}
                error={!!error}
                helperText={getHelperText(error)}
                onBlur={async () =>
                  handleOnBlur({
                    value,
                    onBlur,
                    setHelper,
                    fieldProps,
                    trigger,
                    getFieldState,
                    formState,
                  })
                }
                onChange={(event) => handleOnChange({ event, onChange })}
                FormHelperTextProps={{ component: "div" }}
                key={fieldProps.key}
                required={requiredValue}
                className={classes.inputField}
                label={fieldProps.name}
                variant="outlined"
                size="small"
                id={rest.name}
                multiline={multiline}
                disabled={disabled}
              />
            );
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default InputTextField;
