import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import { Controller, FormProvider } from "react-hook-form";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useForm } from "react-hook-form";
import { customAction } from "../../redux/actions/customActions";
import { userHasAccessToAction } from "../../utils/userPermissions";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import TalentoButton from "./TalentoButton";

const useStyle = makeStyles((theme) => ({
  select: {
    minWidth: 100,
    marginRight: 10,
  },
}));

const ActionsMenu = ({
  actions,
  detailId,
  object,
  gridApi,
  customActions,
  // FROM REDUX
  auth,
  customAction,
}) => {
  const classes = useStyle();
  const { profile_keys, userId } = auth;
  const methods = useForm({ defaultValues: { action: "" } });
  const { handleSubmit, control } = methods;

  const onSubmit = _.debounce(
    (formData) =>
      customAction(formData.action, detailId, object, gridApi, userId),
    500
  );

  const isDisabled = (option) =>
    userHasAccessToAction(option.actionRolesPermissions, profile_keys);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl variant="outlined" className={classes.select} size="small">
          <InputLabel>Ações</InputLabel>
          <Controller
            control={control}
            defaultValue=""
            name="action"
            render={({ field }) => (
              <Select {...field} label="Ações">
                <MenuItem value=""></MenuItem>
                {actions.map((option) => (
                  <MenuItem
                    key={option.customActionId}
                    value={option.customActionId}
                    disabled={isDisabled(option)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        {customActions?.processingStatus === "processing" ? (
          <CircularProgress
            color="secondary"
            size={30}
            style={{ marginLeft: 30 }}
          />
        ) : (
          <TalentoButton
            type="submit"
            variant="contained"
            color="secondary"
            style={{ marginTop: 2 }}
          >
            Executar
          </TalentoButton>
        )}
      </form>
    </FormProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    action: state.action,
    auth: state.auth,
    customActions: state.actions,
  };
};

export default connect(mapStateToProps, { customAction })(
  ActionsMenu
);
