import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FormProvider } from "react-hook-form";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import {
  Box,
  Tooltip,
  Typography,
  Toolbar,
  CssBaseline,
  Paper,
  Grid,
  Card,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  CircularProgress,
} from "@material-ui/core";
import InputTextField from "./InputTextField";
import PhoneField from "../../layout/forms/PhoneField";
import EmailField from "./EmailField";
import DatePickerField from "./DatePickerField";
import SelectBoxField from "../../layout/forms/SelectBoxField";
import SelectBoxFieldUserRoles from "../../layout/forms/SelectBoxFieldUserRoles";
import SelectConnectedField from "./SelectConnectedField/SelectConnectedField";
import BooleanField from "./BooleanField";
import AddressField from "../../layout/forms/AddressField";
import NameField from "../../layout/forms/NameField";
import {
  fetchRecord,
  editRecord,
} from "../../../redux/actions/api/dataApiCalls";
import { clearState } from "../../../redux/actions/layout";
import { customValidation } from "../../../redux/actions/customValidation";
import {
  fetchFormConfig,
  clearFormConfig,
} from "../../../redux/actions/knackconfig";
import { resetInputs } from "../../../redux/actions/formFields";
import { setEditPopUpOpen } from "../../../redux/actions/popUps";
import {
  userHasPermissions,
  disableFieldForUser,
  userCanSeeField,
} from "../../../utils/userPermissions";
import { checkForErrors } from "../../../utils/errors";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import { isObjectEmpty } from "../../../utils/textUtils";
import historyBackFallback from "../../../utils/historyBackFallback";
import useFormValidation, {
  FormTypes,
} from "../../../customHooks/useFormValidation";
import TalentoButton from "../TalentoButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper_header: {
    padding: theme.spacing(1.5),
    color: theme.palette.text.secondary,
  },
  paper_form: {
    padding: 10,
  },
  submitButton: {
    margin: theme.spacing(2),
  },
  formRecord: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const EditForm = (props) => {
  const {
    object,
    objectLabel,
    listUrl,
    id,
    parentId,
    scene,
    view,
    auth,
    record,
    editPopUp,
    helperText,
  } = props;
  const { userId, profile_keys } = props.auth;
  const { userPermissionsField, viewRolesPermissions } = props.userPermissions;
  const [isLoaded, setIsLoaded] = useState(false);
  const [isButtonSaving, setIsButtonSaving] = useState(false);
  const classes = useStyles();

  const methods = useFormValidation({
    type: FormTypes.UPDATE,
    object,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = methods;

  useEffect(() => {
    props.clearFormConfig();
    // props.clearErrors();
    // clearErrors()
    const getConfigAndData = async () => {
      await props.fetchFormConfig(object);
      await props.fetchRecord(id, scene, view, auth);
      setIsLoaded(true);
      if (props.formSubmission.length !== 0) {
        props.resetInputs();
        props.clearState();
        cancelForm(editPopUp);
      }
    };
    getConfigAndData();
    return () => props.resetInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formSubmission]);

  const cancelForm = (editPopUp) => {
    if (editPopUp === false)
      historyBackFallback(`${listUrl}/${parentId || id}`);
    else props.setEditPopUpOpen(false);
  };

  const hasPermission = userHasPermissions({
    userPermissionsField: record?.[userPermissionsField],
    viewRolesPermissions,
    currentUserId: userId,
    userProfileKeys: profile_keys,
  });

  if (hasPermission === false) {
    return (
      <div style={{ marginLeft: 10, marginTop: 100 }}>
        <h1>Não tem permissões para ver este registo</h1>
      </div>
    );
  }

  if (!props.formConfig && !record?.length)
    return <div className={classes.root}></div>;

  const onSubmit = async (formData) => {
    setIsButtonSaving(true);
    const validation = await props.customValidation({
      object,
      formData,
      action: "update",
    });
    if (!validation) return setIsButtonSaving(false);
    const result = await props.editRecord(formData, id, scene, view, auth);
    if (result === "error") setIsButtonSaving(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Toolbar />
      <Grid container style={{ padding: 10 }}>
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paper_header}>
            <Typography>Editar {objectLabel}</Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container style={{ padding: 10 }}>
        {checkForErrors({ errors: props.errors, record, isLoaded }) || (
          <Grid item xs={12}>
            <FormProvider {...methods}>
              <form
                onSubmit={async (event) => {
                  setIsButtonSaving(true);
                  await handleSubmit(onSubmit)(event);
                  setIsButtonSaving(false);
                }}
                className={classes.formRecord}
              >
                <Grid container spacing={2}>
                  {props.formConfig.map((group) => {
                    return (
                      <Grid item xs={12} sm={4} key={group.id}>
                        <Card variant="outlined">
                          <TableContainer>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell colSpan={2}>
                                    {group.field_326}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <ErrorBoundary>
                                <TableBody>
                                  {group.fields.map((field) => {
                                    const isVisible =
                                      userCanSeeField(
                                        field.canView,
                                        profile_keys,
                                        undefined,
                                        field.onlyAllowEdit
                                      )
                                    if (isVisible && !field.hideInEdit) {
                                      switch (field.type) {
                                        case "name":
                                          return (
                                            <NameField
                                              control={control}
                                              key={field.key}
                                              field={field}
                                              recordValues={
                                                _.pick(record, field.key)[
                                                field.key
                                                ]
                                              }
                                              disabled={disableFieldForUser(
                                                field.canEdit,
                                                profile_keys
                                              )}
                                            />
                                          );
                                        case "short_text":
                                        case "number":
                                        case "currency":
                                        case "paragraph_text":
                                        case "link":
                                          return (
                                            <InputTextField
                                              control={control}
                                              key={field.key}
                                              field={field}
                                              recordValues={
                                                _.pick(record, field.key)[
                                                field.key
                                                ]
                                              }
                                              disabled={disableFieldForUser(
                                                field.canEdit,
                                                profile_keys
                                              )}
                                            />
                                          );
                                        case "concatenation":
                                        case "sum":
                                          return null;
                                        case "phone":
                                          if (
                                            typeof _.pick(record, field.key)[
                                            field.key
                                            ] !== "undefined"
                                          ) {
                                            return (
                                              <PhoneField
                                                control={control}
                                                key={field.key}
                                                field={field}
                                                recordValues={
                                                  _.pick(record, field.key)[
                                                    field.key
                                                  ].formatted
                                                }
                                                disabled={disableFieldForUser(
                                                  field.canEdit,
                                                  profile_keys
                                                )}
                                              />
                                            );
                                          }
                                          break;
                                        case "email":
                                          if (
                                            typeof _.pick(record, field.key)[
                                            field.key
                                            ] !== "undefined"
                                          ) {
                                            return (
                                              <EmailField
                                                control={control}
                                                key={field.key}
                                                field={field}
                                                recordValues={
                                                  _.pick(record, field.key)[
                                                    field.key
                                                  ].email
                                                }
                                                disabled={disableFieldForUser(
                                                  field.canEdit,
                                                  profile_keys
                                                )}
                                              />
                                            );
                                          }
                                          break;
                                        case "date_time":
                                          if (
                                            typeof _.pick(record, field.key)[
                                            field.key
                                            ] !== "undefined"
                                          ) {
                                            return (
                                              <DatePickerField
                                                control={control}
                                                key={field.key}
                                                field={field}
                                                recordValues={
                                                  _.pick(record, field.key)[
                                                    field.key
                                                  ].unix_timestamp
                                                    ? _.pick(record, field.key)[
                                                      field.key
                                                    ].unix_timestamp
                                                    : null
                                                }
                                                disabled={disableFieldForUser(
                                                  field.canEdit,
                                                  profile_keys
                                                )}
                                              />
                                            );
                                          }
                                          break;
                                        case "multiple_choice":
                                          if (
                                            typeof _.pick(record, field.key)[
                                            field.key
                                            ] !== "undefined"
                                          ) {
                                            return (
                                              <SelectBoxField
                                                control={control}
                                                key={field.key}
                                                field={field}
                                                recordValues={
                                                  _.pick(record, field.key)[
                                                  field.key
                                                  ]
                                                }
                                                disabled={disableFieldForUser(
                                                  field.canEdit,
                                                  profile_keys
                                                )}
                                              />
                                            );
                                          }
                                          break;
                                        case "user_roles":
                                          if (
                                            typeof _.pick(record, field.key)[
                                            field.key
                                            ] !== "undefined"
                                          ) {
                                            return (
                                              <SelectBoxFieldUserRoles
                                                control={control}
                                                key={field.key}
                                                field={field}
                                                recordValues={
                                                  _.pick(record, field.key)[
                                                  field.key
                                                  ]
                                                }
                                                disabled={disableFieldForUser(
                                                  field.canEdit,
                                                  profile_keys
                                                )}
                                              />
                                            );
                                          }
                                          break;
                                        case "boolean":
                                          if (
                                            typeof _.pick(record, field.key)[
                                            field.key
                                            ] !== "undefined"
                                          ) {
                                            return (
                                              <BooleanField
                                                control={control}
                                                key={field.key}
                                                field={field}
                                                recordValues={
                                                  _.pick(record, field.key)[
                                                  field.key
                                                  ]
                                                }
                                                disabled={disableFieldForUser(
                                                  field.canEdit,
                                                  profile_keys
                                                )}
                                              />
                                            );
                                          }
                                          break;
                                        case "address":
                                          if (
                                            typeof _.pick(record, field.key)[
                                            field.key
                                            ] !== "undefined"
                                          ) {
                                            return (
                                              <AddressField
                                                control={control}
                                                key={field.key}
                                                field={field}
                                                recordValues={
                                                  _.pick(record, field.key)[
                                                  field.key
                                                  ]
                                                }
                                                disabled={disableFieldForUser(
                                                  field.canEdit,
                                                  profile_keys
                                                )}
                                              />
                                            );
                                          }
                                          break;
                                        case "equation":
                                          if (
                                            field.format.equation_type ===
                                            "date"
                                          ) {
                                            return (
                                              <DatePickerField
                                                control={control}
                                                key={field.key}
                                                field={field}
                                                disabled={true}
                                                recordValues={record?.[field.key]?.unix_timestamp}
                                              // recordValues={Math.floor(
                                              //   _.pick(record, field.key)[
                                              //     field.key
                                              //   ] / 31536000
                                              // )}
                                              />
                                            );
                                          }
                                          break;
                                        case "connection":
                                          if (
                                            typeof _.pick(record, field.key)[
                                            field.key
                                            ] !== "undefined"
                                          ) {
                                            return (
                                              <SelectConnectedField
                                                control={control}
                                                key={field.key}
                                                field={field}
                                                recordValues={
                                                  _.pick(record, field.key)[
                                                  field.key
                                                  ]
                                                }
                                                disabled={disableFieldForUser(
                                                  field.canEdit,
                                                  profile_keys
                                                )}
                                                object={object}
                                                getValues={getValues}
                                              />
                                            );
                                          }
                                          break;
                                        default:
                                          return null;
                                      }
                                    }
                                    return null;
                                  })}
                                </TableBody>
                              </ErrorBoundary>
                            </Table>
                          </TableContainer>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
                {helperText && (
                  <Box
                    mt={3}
                    flexDirection={"row"}
                    alignItems={"center"}
                    display={"flex"}
                  >
                    <WarningIcon style={{ marginRight: 8, fontSize: 20 }} />
                    <Typography>{helperText}</Typography>
                  </Box>
                )}
                <Grid
                  xs={12}
                  item
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <TalentoButton
                    onClick={() => cancelForm(editPopUp)}
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                    style={{ marginLeft: "auto" }}
                  >
                    Cancelar
                  </TalentoButton>
                  <Tooltip
                    arrow
                    title="Há erros no formulário"
                    open={!!isObjectEmpty(errors)}
                  >
                    <span>
                      <TalentoButton
                        disabled={isButtonSaving || !!isObjectEmpty(errors)}
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={classes.submitButton}
                        formNoValidate
                        debounceTime={1000}
                      >
                        Gravar Alterações
                      </TalentoButton>
                    </span>
                  </Tooltip>
                  <div style={{ marginLeft: 5, width: 30 }}>
                    {isButtonSaving ? (
                      <CircularProgress color="secondary" size={30} />
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>
              </form>
            </FormProvider>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    record: state.record,
    formConfig: state.formConfig,
    formSubmission: state.formSubmission,
    auth: state.auth,
    editPopUp: state.editPopUp,
    errors: state.errors,
  };
};

export default connect(mapStateToProps, {
  fetchRecord,
  fetchFormConfig,
  resetInputs,
  editRecord,
  clearState,
  clearFormConfig,
  setEditPopUpOpen,
  customValidation,
})(EditForm);
